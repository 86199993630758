import React from "react";
import vector_11 from "../assets/img/vector-11.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
    return(
        <>
        <div className="">
            <div className="row position-relative d-flex justify-content-around ft-top-rectangle pt-3 mx-0">
                <div className="container row">
                    <div className= "col-8 ">
                        <div className="ft-overlap">
                            <div className="ft-socialm-box">
                                <img className="ft-heart-vector" alt="Vector" src={vector_11} />
                                <p className="connect-smedia m-0">Social Media</p>
                                <p className="connect-smedia-slbl m-0">Connect with us</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className= "col-4 d-flex justify-content-end">
                        <div className="row float-right">
                            <div className="col-6">
                                <img className="icon-instagram float-right" alt="Icon instagram" src={require('../assets/img/icon-instagram.png')} />
                                <p className="ft-text-wrapper-5 float-right">Zuus</p>
                            </div>
                            <div className="col-6">
                                <img className="icon-instagram float-right" alt="Icon instagram" src={require('../assets/img/icon-instagram-1.png')} />
                                <p className="float-right">Famile</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" row ft-btm-rectangle mx-0">
                <div className="col d-flex justify-content-center mt-4">
                    <div className="col-4">
                        <div className= "col-12">
                            <Link exact to="/termsofuse" className="text-white">Terms of Use</Link>
                        </div>
                        <div className= "col-12 ">
                            <Link exact to="/termofservice" className="text-white">Your Privacy &amp; Our Responsibility</Link>
                        </div>
                    </div>
                    
                </div>
                <div className="col d-flex justify-content-center mt-4">
                    <div className="col-4">
                        <div className= "col-xs-12 col-sm- d-flex justify-content-start">
                            <Link exact className="text-white">Get in Touch (hello@famile.org)</Link>
                        </div>
                        <div className= "col-xs-12 col d-flex justify-content-center">
                            <p exact className="text-white">#3, 4th Street, Balakrishnapuram, Adambakkam, Chennai - 600 088</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        </>
    )
}