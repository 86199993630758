//export const baseURL = 'http://localhost:8080/family/index.php/';
export const baseURL = 'https://scoil.ltdms.org/backend/index.php/';
export const signup = baseURL + 'cust/signup';
export const updatePhoneSignup = baseURL + 'cust/updatePhoneSignup';
export const updateGeneric = baseURL + 'cust/updateGeneric';
export const verifyVerificationNumber = baseURL + 'cust/verifyVerificationNumber';
export const resendVerificationNumber = baseURL + 'cust/resend_verification_number';
export const login = baseURL + 'cust/login_acc';
export const setpersonalinfo = baseURL + 'cust/setpersonalinfo';
export const getpersonalinfo = baseURL + 'cust/getpersonalinfo';
export const setPreferance = baseURL + 'cust/setpreferance';
export const getPreferance = baseURL + 'cust/getpreferance';
export const emailExists = baseURL + 'cust/email_exists';
export const phoneExists = baseURL + 'cust/phone_exists';