import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getpersonalinfo } from "../constant/url";

export const PersonalInfoView = (props) => {
    const propData = props.propData;
    let navigate = useNavigate();
    const access = propData?.authID;
    const [formData, setFormData] = useState({ fname: '', email:'', gender:'', dob:'', language:'', religion:'', 
    edu_qual:'', profession:'', annual_income:'', food:'', height:'', weight:'', city:'', state:'', country:'', photo:require('../assets/img/signup/profilesample.png') });
    const [errorDisp, setErrorDisp ] = useState({ fname: false, email:false, gender:false, dob:false, language:false, religion:false, 
    edu_qual:false, profession:false, annual_income:false, food:false, height:false, weight:false, city:false, state:false, country:false, photo:false });
  
    console.log("personal view", propData);
    const [loading, setLoading ] = useState(false);

  
    const handlePersonalInfo = async() => {
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append('authId', access);
  
      await axios({
        method: "post",
        url: getpersonalinfo,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 101){
            navigate('/login');
          }else if(res_data.status_code === 200){
            const udata = res_data.data
            setFormData({ fname: udata.fname, email:udata.email, gender:udata.gender, dob:udata.dob, language:udata.language, religion:udata.religion, 
            edu_qual:udata.edu_qual, profession:udata.profession, annual_income:udata.annual_income, food:udata.food, height:udata.height, weight:udata.weight, 
            city:udata.city, state:udata.state, country:udata.country, photo:udata.photo });
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
        setLoading(false);
  }
  

  useEffect(() => {handlePersonalInfo()},[]);
  return(
    <>
    <div className="preferance row mt-5">
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div className="col d-flex justify-content-center">
          <img className="img-fluid" src={formData.photo} alt="profileimg" />
        </div>
        <div className="col d-flex justify-content-center pt-5">
          <img className="img-fluid" src={require('../assets/img/signup/signuptop.png')} alt="heart"  width="129px" height="71px"/>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-3">
        <div className="d-flex justify-content-center">
        <table>
            <tbody>
              <tr>
                <td>Full Name</td>
                <td>:</td>
                <td>{formData.fname}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{formData.email}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>:</td>
                <td>{formData.gender}</td>
              </tr>
              <tr>
                <td>Date Of Birth</td>
                <td>:</td>
                <td>{formData.dob}</td>
              </tr>
              <tr>
                <td>Language</td>
                <td>:</td>
                <td>{formData.language}</td>
              </tr>
              <tr>
                <td>Religion</td>
                <td>:</td>
                <td>{formData.religion}</td>
              </tr>
              <tr>
                <td>Educational Qualification</td>
                <td>:</td>
                <td>{formData.edu_qual}</td>
              </tr>
              <tr>
                <td>Profession</td>
                <td>:</td>
                <td>{formData.profession}</td>
              </tr>
              <tr>
                <td>Annual Incomw</td>
                <td>:</td>
                <td>{formData.annual_income}</td>
              </tr>
              <tr>
                <td>Food</td>
                <td>:</td>
                <td>{formData.food}</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>:</td>
                <td>{formData.weight}</td>
              </tr>
              <tr>
                <td>Height</td>
                <td>:</td>
                <td>{formData.height}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>:</td>
                <td>{formData.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>:</td>
                <td>{formData.state}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>:</td>
                <td>{formData.country}</td>
              </tr>
            </tbody>
          </table>
        </div>
          
      </div>
    </div>
    </>
  );
}