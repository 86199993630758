import React, {useState} from "react";
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import '../css/common.css';
import { resendVerificationNumber } from "../constant/url";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";

export const SubscriptionRate = () => {
  const propData = useLocation()['state'];
  let navigate = useNavigate();
  const access = propData?.authID;
  const phone = propData?.phone;

  const [ loading, setLoading ] = useState(false);
  console.log("Subscription Rate",propData);

  const handleOTP = async() => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('access_code', access);
    await axios({
      method: "post",
      url: resendVerificationNumber,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const res_data = response.data;
        if (res_data.status_code === 101){
          console.log('Api Authentication failed. login again.')
        }else if(res_data.status_code === 200){
            console.log('Signup accepted, Proceeding to verification.');
            navigate('/pmtresult',{ state: propData });
        }else {
            console.log(res_data.status_msg);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
      setLoading(false);
  }

  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center">
        <div className="sor-main-div">
          <div className="">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="col-md-8 col-xs-12 ">
                  <div className="px-5 pt-5  py-3 sor-content">
                      <ul>
                        <li><p>No Public Listing of Private Information (Photos, Details, etc.,)</p></li>
                        <li><p>Lifetime Registration, Only One Time Fee</p></li>
                        <li><p>Unlimited Matches</p></li>
                        <li><p>Email Notifications of Potential & Interested Matches</p></li>
                        <li><p>SMS Alerts</p></li>
                        <li><p>Not Just Matrimony, A Lifelong Association</p></li>
                        <li><p>Invitation to Our Events</p></li>
                      </ul>
                      <h3 className="text-center mt-5">Rs. 3000 /-</h3>
                      <div className="d-grid gap-2 col-7 mx-auto my-4 ">
                        <button type="submit" className="btn btn-primary bg-dark p-2" onClick={() => handleOTP()} >SUBSCRIBE</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-4 position-relative">
              <div className="col-2">
                <div className="col d-flex justify-content-start">
                  <img className="img-fluid btm-flower-left-2" src={require('../assets/img/signup/btmflower2.png')} alt="profileimg" />
                </div>
              </div>
              <div className="col-8 mt-5 pt-5 d-flex justify-content-center">
                <div className="inp-box5right">
                    <div className="d-flex justify-content-around">
                        <img className="img-fluid" alt="Famile mm" src={require('../assets/img/signup/fifthright3.png')} />
                        <img className="img-fluid" alt="Famile mm" src={require('../assets/img/signup/fifthright1.png')} />
                        <img className="img-fluid" alt="Famile mm" src={require('../assets/img/signup/fifthright2.png')} />
                    </div>
                </div>
              </div>
              <div className="col-2">
                <div className="col d-flex justify-content-end">
                  <img className="img-fluid btm-flower-right-2" src={require('../assets/img/signup/btmflower2.png')} alt="profileimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
          <Footer />
      </div>
    </>
  );
};
