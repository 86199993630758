import React,{useState, useContext} from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { signup, } from "../constant/url";

export const RegisterBox = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ phone: '', name: ''});
    const [ loading, setLoading ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState('');

    const validateInput = () =>{
        if(formData.phone.length <10 || isNaN(formData.phone)){
            setErrorMsg('Please enter valid Phone number');
            return false;
        }else if(formData.name.length < 4){
            setErrorMsg('Please Provide valid name');
            return false;
        }

        return true;
    }

    const handleSignUp = async(e) => {

        if(!validateInput()){
            return false;
        }

        setLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('name', formData.name);
        bodyFormData.append('phone', formData.phone);
        await axios({
          method: "post",
          url: signup,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            const res_data = response.data;
            if (res_data.status_code === 101){
              console.log('Api Authentication failed. login again.')
            }else if(res_data.status_code === 200){
                navigate('/personalinfo', { state: {authID: res_data.authId, phone:formData.phone} } );
            }else {
                setErrorMsg(res_data.status_msg);
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
          setLoading(false);
      }
    const handleChangeFormData = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, // copy the current properties of "json"
            [name]: value // update the "name" property
        });
    }
return(
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 position-relative d-flex justify-content-center pt-5 ">
            <h1 className="text-center position-absolute mt-5 pt-2">Famile</h1>
            
            <div className="login-left mt-5 pt-2">
            
                <div className="rectangle rectangle4" />
                <div className="rectangle rectangle3" />
                <div className="rectangle rectangle2" />
                <div className="rectangle rectangle1 pt-5" >
                    <h2 className="text-center">Request</h2>
                    <p className="text-center mb-3">Enter your details below</p>
                    <p className="text-center error text-capitalize">{errorMsg}</p>
                    <div className="col-11">
                        <div className="mt-5 ms-5 me-3 mb-4">
                            <input required type="text" value={formData.name} className="form-control p-2" placeholder="Name" name="name" onChange={(e)=>handleChangeFormData(e)}/>
                        </div>
                        <div className="input-group mx-5 mb-4">
                            <div className="col-2 me-2">
                                <select className="form-select p-2">
                                    <option>IN</option>
                                </select>
                            </div>
                            <div className="col-2 me-2">
                                <input  type="numeric" maxLength="10" minLength="10" value="+91" className="form-control p-2 me-2" 
                                placeholder="Phone" name="phone_code" disabled  />
                            </div>
                            <div className="col-6">
                                <input  type="numeric" maxLength="10" minLength="10" value={formData.phone} className="form-control p-2" 
                                placeholder="Phone" name="phone" onChange={(e)=>handleChangeFormData(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="d-grid gap-2 col-7  mx-5">
                            <button className="btn btn-primary bg-dark text-white" type="button" onClick={() => handleSignUp()} disabled={loading}>JOIN</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
);
}
