import React,{useContext, useEffect, useState} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { AccessContext } from '../constant/AccessContext';

import '../css/common.css'; 
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { PersonalInfoEdit } from "../component/PersonalInfoEdit";
import { PersonalInfoView } from "../component/PersonalInfoView";

export const PersonalInfoPage = (props) => {
  let navigate = useNavigate();
  const state = useLocation()['state'];
  const access = useContext(AccessContext).authID;
  let propData ={...state,login:false};

  if(access !== undefined && access !== null && access !== ''){
    propData ={authID:access, login:true};
  }

  const [dispBox, setDispBox ] = useState(<PersonalInfoEdit propData={propData}/>);
  const [dispBtn, setDispBtn] = useState('EDIT');

  const handleEditView = (e) =>{
    if(e === 'EDIT'){
      setDispBtn('VIEW');
      setDispBox(<PersonalInfoEdit propData={propData}/>);
    }else if(e === 'VIEW'){
      setDispBtn('EDIT');
      setDispBox(<PersonalInfoView propData={propData}/>);
    }
  }

  useEffect(() =>{
    if(propData.authID === undefined || !propData.authID){
      return;
      navigate('/login');
    }

    if(access !== undefined && access !== null && access !== ''){
      setDispBox(<PersonalInfoView propData={propData}/>)
    }
    
  },[access]);

  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center">
        <div className="main-div">
          <div className="pt-5">
            <div className="position-relative">
              <h2 className="text-center">Personal Information</h2>
              <div className="d-grid gap-2 col-1 bg-light position-absolute end-0 mt-5 me-3 top-0">
                <button className="btn shadow-sm btn-light btn-lg mt-1" name={dispBtn} onClick={(e)=>handleEditView(e.target.name)}>{dispBtn}</button>
              </div>
            </div>
            {
              dispBox
            }
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
          <Footer />
      </div>
    </>
  );
};
