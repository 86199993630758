import React, {useState} from "react";
import { AccessContext } from '../constant/AccessContext';
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import stateDist from "../constant/state-dist.json";
import religion from "../constant/religion.json";
import language from "../constant/languages.json";

import ReactFileReader from 'react-file-reader';
import { setpersonalinfo } from "../constant/url";

export const PersonalInfoEdit = (props) => {
    const propData = props.propData;
    let navigate = useNavigate();
    const access = propData?.authID;
    const [formData, setFormData] = useState({ fname: '', email:'', gender:'', dob:'', language:'', religion:'', 
    edu_qual:'', profession:'', annual_income:'', food:'', height:'', weight:'', city:'', state:'', country:'', photo:require('../assets/img/signup/profilesample.png') });
    const [errorDisp, setErrorDisp ] = useState({ fname: false, email:false, gender:false, dob:false, language:false, religion:false, 
    edu_qual:false, profession:false, annual_income:false, food:false, height:false, weight:false, city:false, state:false, country:false, photo:false });
    const [retMsg, setRetMsg] = useState({type:'', msg: ''});

    console.log("personal", propData);
    const [loading, setLoading ] = useState(false);
  
    const validateInput = () => {
      if(formData.fname.length === 0){
        setErrorDisp(true);
        return false;
      }else if(!formData.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
        setErrorDisp(true);
        return false;
      }else if(formData.gender.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.dob.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.language.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.religion.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.edu_qual.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.profession.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.annual_income.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.food.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.height.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.weight.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.city.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.state.length === 0){
        setErrorDisp(true);
        return false;
      }else if(formData.country.length === 0){
        setErrorDisp(true);
        return false;
      }
  
      return true;
    }
  
    const handleFiles = (files) => {
      console.log(files)
      setFormData({
        ...formData, // copy the current properties of "json"
        photo: files.base64 // update the "name" property
      });
    }
  
    const handlePersonalInfo = async(e) => {
      e.preventDefault();
  
      if(!validateInput()){
        return false;
      }
      setLoading(true);
      var bodyFormData = new FormData();
      bodyFormData.append('authId', access);
      bodyFormData.append('fname', formData.fname);
      bodyFormData.append('email', formData.email);
      bodyFormData.append('gender', formData.gender);
      bodyFormData.append('dob', formData.dob);
      bodyFormData.append('language', formData.language);
      bodyFormData.append('religion', formData.religion);
      bodyFormData.append('edu_qual', formData.edu_qual);
      bodyFormData.append('profession', formData.profession);
      bodyFormData.append('annual_income', formData.annual_income);
      bodyFormData.append('food', formData.food);
      bodyFormData.append('height', formData.height);
      bodyFormData.append('weight', formData.weight);
      bodyFormData.append('city', formData.city);
      bodyFormData.append('state', formData.state);
      bodyFormData.append('country', formData.country);
      bodyFormData.append('photo', formData.photo);
  
      await axios({
        method: "post",
        url: setpersonalinfo,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          const res_data = response.data;
          if (res_data.status_code === 101){
            navigate('/login');
          }else if(res_data.status_code === 200){
            if(!propData?.login){
              navigate('/termofservice',{ state: propData });
            }
            setRetMsg({type:'success', msg: res_data.status_msg});
          }else{
            setRetMsg({type:'error', msg: res_data.status_msg});
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
        setLoading(false);
  }
  
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData, // copy the current properties of "json"
        [name]: value // update the "name" property
      });
  }

  return(
    <>
    <div className="row">

      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div className="col d-flex justify-content-center">
          <img className="img-fluid" src={formData.photo} alt="profileimg" />
        </div>
        <ReactFileReader 
        fileTypes={[".png", ".jpg"]}
        base64={true}
        multiple={false}
        key={Math.random()}
        handleFiles={handleFiles}>
        <div className="d-grid gap-2 col-8 mx-auto mt-4 ">
          <button className="btn btn-lg btn-outline-dark" >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none" className="me-2 ">
            <path d="M14.9333 15H18.4167C20.9375 15 23 13.6548 23 11.15C23 8.6452 20.5708 7.39809 18.6 7.3C18.1925 3.38276 15.3458 1 12 1C8.8375 1 6.80067 3.10875 6.13333 5.2C3.38333 5.4625 1 7.22079 1 10.1C1 12.9792 3.475 15 6.5 15H9.06667" stroke="#141414" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 9.87029L12 7L9 9.87029M12 18.5V7.71757" stroke="#141414" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
            Upload Photo</button>
        </div>
        </ReactFileReader>
        <div className="col d-flex justify-content-center pt-5">
          <img className="img-fluid" src={require('../assets/img/signup/signuptop.png')} alt="heart"  width="129px" height="71px"/>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-3">
              <p className="text-center">Enter your details below</p>
        <form  onSubmit={handlePersonalInfo}>
          <div className="row">
            <div className="col-10">
              <input  type="text" maxLength={30} minLength={3} className={`form-control mt-3 p-2 ${(errorDisp && formData.fname?.length <= 1 )? 'is-invalid':''}`} placeholder="Name" name="fname" 
              value={formData.fname} onChange={(e) => handleChangeFormData(e)} />
            </div>
            <div className="col-2">
              {
                  (errorDisp && formData.fname?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please fill</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <input  type="text" className={`form-control mt-3 p-2 ${(errorDisp && !formData.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) )? 'is-invalid':''}`} placeholder="Email" name="email" value={formData.email} onChange={(e) => handleChangeFormData(e)}/>
            </div>
            <div className="col-2">
              {
                  (errorDisp && !formData.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please fill</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.gender?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="gender" onChange={(e) => handleChangeFormData(e)}>
                <option selected>Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
          </div>
            <div className="col-2">
              {
                  (errorDisp && formData.gender?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <input  type="text" className={`form-control mt-3 p-2 ${(errorDisp && formData.dob?.length <= 1 )? 'is-invalid':''}`} 
              placeholder="Date of Birth" name="dob" value={formData.dob} onChange={(e) => handleChangeFormData(e)}/>
            </div>
            <div className="col-2">
              {
                  (errorDisp && formData.dob?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Fill</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.language?.length <= 1 )? 'is-invalid':''}`} aria-label="Language" name="language"  onChange={(e) => handleChangeFormData(e)}>
                <option selected>Language</option>
                {
                  language.languages?.map((item) => {
                    return <option value={item} >{item}</option>
                  })
                }
              </select>
            </div>
            <div className="col-2">
              {
                  (errorDisp && formData.language?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.religion?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="religion" onChange={(e) => handleChangeFormData(e)}>
                <option selected>Religion</option>
                {
                    religion.religion?.map((item) => {
                      return <option value={item} >{item}</option>
                    })
                  }
              </select>
          </div>
            <div className="col-2">
              {
                  (errorDisp && formData.religion?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.edu_qual?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="edu_qual" onChange={(e) => handleChangeFormData(e)}>
                <option value="" selected>Education Qualification</option>
                <option value="Batchelors" >Batchelors</option>
                <option value="Masters" >Masters</option>
                <option value="Doctorate" >Doctorate</option>
              </select>
          </div>
            <div className="col-2">
              {
                  (errorDisp && formData.edu_qual?.length <= 1 )&&
                  <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <input  type="text" maxLength={30} minLength={3} className={`form-control mt-3 p-2 ${(errorDisp && formData.profession?.length <= 1 )? 'is-invalid':''}`} placeholder="Profession" 
              name="profession" value={formData.profession} onChange={(e) => handleChangeFormData(e)} />
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.profession?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Fill</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.annual_income?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="annual_income" onChange={(e) => handleChangeFormData(e)}>
                <option value="" selected>Annual Income</option>
                <option value="No Income" >No Income</option>
                <option value="Upto 2 Lakhs" >Upto 2 Lakhs</option>
                <option value="2 Lakhs - 5 Lakhs" >2 Lakhs - 5 Lakhs</option>
                <option value="5 Lakhs - 10 Lakhs" >5 Lakhs - 10 Lakhs</option>
                <option value="10 Lakhs - 18 Lakhs" >10 Lakhs - 18 Lakhs</option>
                <option value="18 Lakhs - 30 Lakhs" >18 Lakhs - 30 Lakhs</option>
                <option value="30 Lakhs - 50 Lakhs" >30 Lakhs - 50 Lakhs</option>
                <option value="50 Lakhs - 1 Crore" >50 Lakhs - 1 Crore</option>
                <option value="Above 1 Crore" >Above 1 Crore</option>
              </select>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.annual_income?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.food?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="food" onChange={(e) => handleChangeFormData(e)}>
                <option value="" selected>Food</option>
                <option value="Veg" >Veg</option>
                <option value="Non-Veg" >Non-Veg</option>
              </select>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.food?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
            <input  type="text" className={`form-control mt-3 p-2 ${(errorDisp && formData.height?.length <= 1 )? 'is-invalid':''}`} placeholder="Height" name="height" value={formData.height} onChange={(e) => handleChangeFormData(e)}/>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.height?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Fill</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
            <input  type="text" className={`form-control mt-3 p-2 ${(errorDisp && formData.weight?.length <= 1 )? 'is-invalid':''}`} 
            placeholder="Weight" name="weight" value={formData.weight} onChange={(e) => handleChangeFormData(e)}/>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.weight?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Fill</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
            <input  type="text" className={`form-control mt-3 p-2 ${(errorDisp && formData.city?.length <= 1 )? 'is-invalid':''}`} 
            placeholder="city" name="city" value={formData.city} onChange={(e) => handleChangeFormData(e)}/>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.city?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.state?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="state" onChange={(e) => handleChangeFormData(e)}>
                <option value="" selected>State</option>
                {
                  stateDist.states?.map((item) => {
                    return <option value={item.state} >{item.state}</option>
                  })
                }
              </select>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.state?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
                }
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <select  className={`form-select mt-3 p-2 ${(errorDisp && formData.country?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="country" onChange={(e) => handleChangeFormData(e)}>
                <option value="" selected>Country</option>
                <option value="India" >India</option>
              </select>
            </div>
              <div className="col-2">
                {
                    (errorDisp && formData.country?.length <= 1 )&&
                    <p className="error text-nowrap ms-2 mt-4 text-nowrap text-center text-capitalize" >Please Select</p>
                }
            </div>
          </div>
          
          <div className="d-grid gap-2 col-8 mx-auto mt-4 ">
            <p className={`text-center ${retMsg.type}`}>{retMsg.msg}</p>
              <button type="submit" className="btn btn-primary bg-dark p-2"  disabled={loading} >JOIN</button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}