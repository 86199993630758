import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp";
import { LoginHome } from "./pages/LoginHome";
import { Login } from "./pages/Login";
import { PersonalInfoPage } from "./pages/PersonalInfoPage";
import { PaymentResult } from "./pages/PaymentResult";
import { Subcscription } from "./pages/Subcscription";
import { TermsOfService } from "./pages/TermsOfService";
import { TermsOfUse } from "./pages/TermsOfUse";
import { Verification } from "./pages/Verification";
import { Preference } from "./pages/Preference";
import TestPage from "./pages/TestPage";
import { Privacy } from "./pages/Privacy";
import { useNavigate } from "react-router-dom";
import { AccessContext } from './constant/AccessContext';
import { SubscriptionRate } from './pages/SubscriptionRate';
import { Logout } from './pages/Logout';

function App() {
  const navigate = useNavigate();
  const [authID, setAuthID] = useState(null);
  // Handle login with user credentials
  const handleLogin = (access_code) => {
    if (access_code !== '') {
      setAuthID(access_code);
      if (access_code) {
        navigate("/");
      }
    } else {
      alert('Invalid username or password');
    }
  };

  const handleAuthID = (ret_item) => {
    if(ret_item !== undefined && ret_item !== ''){
      setAuthID(ret_item);
    }
  }
  // Handle logout
  const handleLogout = (e) => {
    if (e){setAuthID(null)} ;
  };
  return (
    <div className="App">
      <AccessContext.Provider value={{authID:authID, handleLogout:handleLogout(), setAuthID:handleAuthID()}}>
        <Routes>
          <Route exact path="/" element={ <SignUp onLogOut={handleLogout}/> } />
          <Route exact path="/loginhome" element={ <LoginHome/> } />
          <Route exact path="/login" element={ <Login onLogin={handleAuthID}/> } />
          <Route exact path="/personalinfo" element={ <PersonalInfoPage/> }/>
          <Route exact path="/subscriptionrate" element={ <SubscriptionRate/> } />
          <Route exact path="/pmtresult" element={ <PaymentResult/> } />
          <Route exact path="/preference" element={ <Preference/> } />
          <Route exact path="/subscription" element={ <Subcscription/> } />
          <Route exact path="/termofservice" element={ <TermsOfService/> } />
          <Route exact path="/termsofuse" element={ <TermsOfUse/> } />
          <Route exact path="/verfication" element={ <Verification accessId={handleAuthID}/> } />
          <Route exact path="/privacy" element={ <Privacy/> } />
        </Routes>
      </AccessContext.Provider>
    </div>
  )
}

export default App