import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AccessContext } from '../constant/AccessContext';
import '../css/common.css';
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { PreferanceEdit } from "../component/PreferanceEdit";
import { PreferanceView } from "../component/PreferanceView";
 
export const Preference = () => {
  const state = useLocation()['state'];
  let navigate = useNavigate();
  const access = useContext(AccessContext).authID;
  let propData =state;
  if(access !== undefined && access !== null && access !== ''){
    propData ={authID:access, phone:''};
  }
  const [dispBox, setDispBox ] = useState(<PreferanceEdit propData={propData}/>);
  const [dispBtn, setDispBtn] = useState('EDIT')
  
  const handleEditView = (e) =>{
    console.log(e);
    if(e === 'EDIT'){
      setDispBtn('VIEW');
      setDispBox(<PreferanceEdit propData={propData}/>);
    }else if(e === 'VIEW'){
      setDispBtn('EDIT');
      setDispBox(<PreferanceView propData={propData}/>);
    }
  }


  const  handleAccesFlow = async () =>  {
      if(access !== undefined && access !== null && access !== ''){
        setDispBox(<PreferanceView  propData={propData}/>);
        setDispBtn('EDIT');
      }else{
        setDispBtn('VIEW');
      }
    return true;
  }

  const handleLoginRegister = (e) =>{
    if(e === 'login'){
      navigate('/login');
    }
  }


  useEffect(() => {
    if(propData.authID === undefined || !propData.authID){
      navigate('/login');
      return;
    }

    handleAccesFlow();
    
  },[]);

  console.log('preferance', state);
  return (
    <>
      <Header acc_type="login" ret_type={(e) => handleLoginRegister(e)}/>
      <div className="container ">
        <div className="main-div" style={{height: "750px"}}>
          <div className="col">
            <div className="row">
              <div className="col pt-5">
              <div className="d-grid gap-2 col-1 bg-light position-absolute end-0 me-5">
                  <button className="btn shadow btn-light btn-lg " name={dispBtn} onClick={(e)=>handleEditView(e.target.name)}>{dispBtn}</button>
                </div>
                <h2 className="text-center">Preferences</h2>
                {
                  dispBox
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
          <Footer />
      </div>
    </>
  );
};
