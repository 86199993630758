import React from "react";
import { useNavigate,useLocation } from "react-router-dom";
import '../css/common.css';
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
import { Link } from "react-router-dom";

export const TermsOfService = () => {
  const propData = useLocation()['state'];
  let navigate = useNavigate();
  const access = propData?.authID;
  const phone = propData?.phone;
  console.log("terms of service", propData);
  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center">
        <div className="main-div">
          <div className="">
            <div className="row">
              <div className="col d-flex justify-item-center">
                <div className="col p-5 mx-5">
                  <h2 className="text-center">Terms of Service</h2>
                  <div className="px-5 m-3 my-5 py-3 tos-content">
                    <ul>
                        <li><p>Famile Matrimony is a subsidiary of 1D1S Entercon Private Limited, with registered address at #3, 4th Street, Balakrishnapuram, Adambakkam, Chennai – 600 088</p></li>
                        <li><p>Famile Matrimony is functioning as a Matrimonial Networking Community for people of all ages and is currently operating to serve people living in India.</p></li>
                        <li><p>Famile Matrimony ensures that all the patrons who are being accepted are of rational behavior and do not subscribe to the idea of Caste.</p></li>
                        <li><p>Registration fees with Famile Matrimony is Rs.3000 (Inclusive of All Taxes), this Registration is a one-time fee only.</p></li>
                        <li><p>Registered Patrons will be sent weekly matches by email based on availability of matches and based on mutual interest with like-minded potential partner profiles.</p></li>
                        <li><p>While Famile Matrimony does a preliminary enquiry with all patrons regarding their identity, information and enquires about their serious intent to marry, patrons are required to conduct their own due-diligence
                                    and background-verification of any and all potential partners that they are interested in. Famile
                                    Matrimony does not vouch for the validity or veracity of any of the details provided by patrons.</p></li>
                      </ul>
                  </div>
                    
                  <div className="d-grid gap-2 col-3 mx-auto mt-4 ">
                      <button type="submit" className="btn btn-primary bg-dark p-2" onClick={() => navigate('/subscriptionrate',{ state: propData })} >AGREE & ACCEPT</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="col d-flex justify-content-start">
                  <img className="img-fluid btm-flower-left" src={require('../assets/img/signup/btmflower2.png')} alt="profileimg" />
                </div>
              </div>
              <div className="col">
                <div className="col d-flex justify-content-end">
                  <img className="img-fluid btm-flower-right" src={require('../assets/img/signup/btmflower2.png')} alt="profileimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
          <Footer />
      </div>
    </>
  );
};
