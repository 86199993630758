import React from "react";
import "../css/loginhome.css";
import rectangle_1234 from "../assets/img/rectangle-1234.svg";
import vector_11 from "../assets/img/vector-11.svg";
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";
export const LoginHome = () => {
  return (
    <div className="LOGIN-HOME">
      <div className="div">
        <div className="Header">
          <Header />
        </div>
        <div className="overlap">
          <div className="rectangle" />
          <div className="group-body">
            <h1 className="h-1">Personal Information</h1>
            <div className="rectangle-4" />
            <div className="frame">
              <div className="edit">EDIT</div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-2">Height</div>
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-4">180 cm</div>
            </div>
            <div className="group-3">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">Weight</div>
              <div className="text-wrapper-4">80 kg</div>
            </div>
            <div className="group-4">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">Education Qualification</div>
              <div className="text-wrapper-4">BE Mechanical Engineering</div>
            </div>
            <div className="group-5">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">Food</div>
              <div className="text-wrapper-4">Non- Vegetarian</div>
            </div>
            <div className="group-6">
              <div className="text-wrapper-2">Annual Income</div>
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-4">10 lakh</div>
            </div>
            <div className="group-7">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">Language</div>
              <div className="text-wrapper-4">Tamil</div>
            </div>
            <div className="group-8">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">Religion</div>
              <div className="text-wrapper-4">Christian</div>
            </div>
            <div className="group-9">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">City</div>
              <div className="text-wrapper-4">Chennai</div>
            </div>
            <div className="group-10">
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-2">State</div>
              <div className="text-wrapper-4">Tamil Nadu</div>
            </div>
            <div className="group-11">
              <div className="text-wrapper-2">Country</div>
              <div className="text-wrapper-3">:</div>
              <div className="text-wrapper-4">India</div>
            </div>
            <img className="rectangle-3" alt="Rectangle" src={rectangle_1234} />
            <div className="group-12">
              <div className="text-wrapper-5">Ravi Sharma Krishnan</div>
              <div className="text-wrapper-6">Name</div>
              <div className="text-wrapper-7">:</div>
            </div>
            <div className="navbar">
              <div className="text-wrapper-8">Age</div>
              <div className="text-wrapper-9">:</div>
              <div className="text-wrapper-10">27</div>
              <div className="text-wrapper-11">(16-Oct-1999)</div>
            </div>
            <div className="group-13">
              <div className="text-wrapper-8">Email</div>
              <div className="text-wrapper-9">:</div>
              <div className="text-wrapper-10">ravisharma@gmail.com</div>
            </div>
            <div className="group-14">
              <div className="text-wrapper-8">Gender</div>
              <div className="text-wrapper-9">:</div>
              <div className="text-wrapper-10">Male</div>
            </div>
            <div className="group-15">
              <div className="text-wrapper-8">Profession</div>
              <div className="text-wrapper-9">:</div>
              <div className="text-wrapper-10">Software Engineer</div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="ft-footer-block" style={{position: "absolute",top:"5px" }}>
        <Footer />
      </div>
    </div>
  );
};
