import React from "react";
import '../css/common.css';
import { Footer } from "../component/Footer";
import { Header } from "../component/Header";

export const Subcscription = () => {
  return (
    <>
      <Header />
      <div className="container ">
        <div className="main-div" style={{height: "750px"}}>
          <div className="">
            <div className="row">
              <div className="col pt-5">
                <h2 className="text-center">Subscription</h2>
                <div className="col mt-5">
                
                  <table className="table ">
                    <tr >
                      <td className="lightFont">
                        Gender
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td >
                        Female
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        Age
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td>
                        22 - 30
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        Language
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td >
                        Tamil
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        Religion
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td >
                        Christian
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        Food
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td >
                      Non-Veg
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        State
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td>
                        TamilNadu
                      </td>
                    </tr>
                    <tr >
                      <td className="lightFont">
                        Country
                      </td>
                      <td style={{    justifyContent: "center"}}>
                        :
                      </td>
                      <td >
                        India
                      </td>
                    </tr>
                  </table>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-footer-block">
          <Footer />
      </div>
    </>
  );
};
