import React from "react";
import { AccessContext } from '../constant/AccessContext';
import { Link, NavLink } from "react-router-dom";

export const Header = (props) => {
    const access = React.useContext(AccessContext).authID;
    const acc_type = props.acc_type;

    const LoginRegisterbtn = () =>{
        if (acc_type === 'login'){
            return(
                <div class="d-grid gap-2 col-6 mx-auto d-flex justify-content-end">
                    <button className="btn btn-light btn-lg py-1 login-btn" width={{ width: "112px" }} onClick={() => props.ret_type('login')}>LOGIN</button>
                </div>
            )
            
        }else if (acc_type === 'register'){
            return(
                <div class="d-grid gap-2 col-6 mx-auto d-flex justify-content-end">
                    <button className="btn btn-light btn-lg py-1 login-btn" width={{ width: "112px" }} onClick={() => props.ret_type('register')}>Register</button>
                </div>
            )
        }else{
            return <></>
        }
    }
    return(
        <nav className="navbar  navbar_bg">
            <div className="container">
                    <div className="col d-flex justify-content-start">
                        <NavLink exact className="navbar-brand" to="/">
                            <img src={require('../assets/logo/logo_light.png')} srcSet={require('../assets/logo/logo_light.png')} alt="Famile Logo" width="110" height="90"/>
                        </NavLink>
                        { access &&
                            <div className=" col-12 d-none d-sm-none d-md-none d-lg-block d-xl-block d-flex justify-content-center mt-5">
                                    <ul className="navbar-nav main-nav me-auto mb-2 mb-lg-0">
                                        <li className="main-nav-item">
                                            <NavLink exact activeClassName="main-nav-item-active" className="nav-NavLink text-white" aria-current="page" to="/personalinfo">Your Profile</NavLink>
                                        </li>
                                        <li className="main-nav-item">
                                            <NavLink exact activeClassName="main-nav-item-active" className="nav-NavLink text-white" to="/subscription">Subscription</NavLink>
                                        </li>
                                        <li className="main-nav-item">
                                            <NavLink exact activeClassName="main-nav-item-active" className="nav-NavLink text-white" to="/preference">Preferance</NavLink>
                                        </li>
                                        <li className="main-nav-item">
                                            <NavLink exact activeClassName="main-nav-item-active" className="nav-NavLink text-white" to="/" >Logout</NavLink>
                                        </li>
                                    </ul>
                            </div>
                        }
                    </div>
                    { access ?
                    <>
                    <button className="navbar-toggler d-block  d-sm-block d-md-block d-lg-none d-xl-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none xl-navbar bg-white" >
                        <div className="collapse navbar-collapse d-lg-none d-xl-none" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink exact className="nav-NavLink active text-dark" aria-current="page" to="/personalinfo">Your Profile</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact className="nav-NavLink text-dark" to="/subscription">Subscription</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact className="nav-NavLink text-dark" to="/preference">Preferance</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact className="nav-NavLink text-dark" to="/" >Logout</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </>
                    :
                    <>
                        <LoginRegisterbtn />
                    </>
                    }
            </div>
        </nav>
    )
}