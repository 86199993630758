import React, { useEffect, useState } from "react";
import axios from "axios";
import { getPreferance } from "../constant/url";

export const PreferanceView = (props) => {
  const access = props?.propData.authID
  const [ formData, setFormData] = useState({gender:'', fromAge:'', toAge:'',food:'',language:'',religion:'',state:'', country:''});
  const [retMsg, setRetMsg] = useState({type:'', msg: ''});

  console.log('pview', props);
  const handlePreferance = async() => {

    var bodyFormData = new FormData();
    bodyFormData.append('authId', access);

    await axios({
      method: "post",
      url: getPreferance ,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      const res_data = response.data;
      if (res_data.status_code === 101){

      }else if(res_data.status_code === 200){
        const retData = res_data.data;
        console.log('ret',retData);
        setFormData({ gender: retData.gender, fromAge:retData.fromAge, toAge:retData.toAge, food:retData.food ,language:retData.language,
          religion:retData.religion, state:retData.state, country:retData.country })
        setRetMsg({type:'success', msg: res_data.status_msg});
      }else{
        setRetMsg({type:'error', msg: res_data.status_msg});
      }
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
    console.log(formData);
}

useEffect(() => {handlePreferance()},[]);
    return(
        <div className="col mt-5">
          <div className="d-flex justify-content-center">
            <div className="col-3">
              <table className="table">
                <tbody>
                  <tr >
                    <td className="lightFont">
                      Gender
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td >
                      {formData.gender}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      Age
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td>
                    {formData.fromAge + ' - ' +formData.toAge}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      Language
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td >
                    {formData.language}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      Religion
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td >
                    {formData.religion}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      Food
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td >
                    {formData.food}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      State
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td>
                    {formData.state}
                    </td>
                  </tr>
                  <tr >
                    <td className="lightFont">
                      Country
                    </td>
                    <td style={{    justifyContent: "center"}}>
                      :
                    </td>
                    <td >
                    {formData.country}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    );
}