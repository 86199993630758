import React, { useState } from "react";
import axios from "axios";
import stateDist from "../constant/state-dist.json";
import religion from "../constant/religion.json";
import language from "../constant/languages.json";
import { setPreferance } from "../constant/url";

export const PreferanceEdit = (props) => {
  const propData = props.propData;
    const [ formData, setFormData] = useState({gender:'', fromAge:'', toAge:'',food:'',language:'',religion:'',state:'', country:''});
    const [errorDisp, setErrorDisp ] = useState({gender:false, fromAge:false, toAge:false,food:false,language:false,religion:false,state:false, country:false});
    const [retMsg, setRetMsg] = useState({type:'', msg: ''});
    const [loading, setLoading ] = useState(false);

    const access = propData.authID ;
    const handleChangeFormData = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, // copy the current properties of "json"
            [name]: value // update the "name" property
          });
      } 
      const validateInput = () => {
        if(formData.gender.length === 0){
          setErrorDisp(true);
          return false;
        }else if(formData.toAge <= 18 && formData.toAge < formData.fromAge){
          setErrorDisp(true);
          return false;
        }else if(formData.language.length === 0){
          setErrorDisp(true);
          return false;
        }else if(formData.religion.length === 0){
          setErrorDisp(true);
          return false;
        }else if(formData.food.length === 0){
          setErrorDisp(true);
          return false;
        }else if(formData.state.length === 0){
          setErrorDisp(true);
          return false;
        }else if(formData.country.length === 0){
          setErrorDisp(true);
          return false;
        }
        return true;
      }

      const handlePreferance = async(e) => {
        e.preventDefault();

        if(!validateInput()){
          return false;
        }
        setLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('authId', access);
        bodyFormData.append('gender', formData.gender);
        bodyFormData.append('toAge', formData.toAge);
        bodyFormData.append('fromAge', formData.fromAge);
        bodyFormData.append('language', formData.language);
        bodyFormData.append('religion', formData.religion);
        bodyFormData.append('food', formData.food);
        bodyFormData.append('state', formData.state);
        bodyFormData.append('country', formData.country);
    
        await axios({
          method: "post",
          url: setPreferance ,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            const res_data = response.data;
            if (res_data.status_code === 101){

            }else if(res_data.status_code === 200){
              setRetMsg({type:'success', msg: res_data.status_msg});
            }else{
              setRetMsg({type:'error', msg: res_data.status_msg});
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
          setLoading(false);
    }
      return(
        <form method="post" onSubmit={handlePreferance} className="col mt-5">
          <div className="d-flex justify-content-center">
            <div className="col-6">
              <table className="table">
                <tbody>
                <tr >
                  <td className="lightFont">
                    Gender
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td >
                    <div>
                      <select  className={`form-select sel-td ${(errorDisp && formData.gender?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="gender" onChange={(e) => handleChangeFormData(e)}>
                        <option selected>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    Age
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td>
                    <input className={`input-td form-control me-3 ${(errorDisp && formData.fromAge?.length <= 1 )? 'is-invalid':''}`} 
                    name="fromAge" max={99} min={18} placeholder="From Age" value={formData.fromAge} onChange={(e) => handleChangeFormData(e)}/> 
                    <input className={`input-td form-control ${(errorDisp && formData.toAge?.length <= 1 )? 'is-invalid':''}`} 
                    max={99} min={18} name="toAge" placeholder="To Age" value={formData.toAge} onChange={(e) => handleChangeFormData(e)}/> 
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    Language
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td >
                    <select  className={`form-select sel-td  ${(errorDisp && formData.language?.length <= 1 )? 'is-invalid':''}`} aria-label="Language" name="language"  onChange={(e) => handleChangeFormData(e)}>
                      <option selected>Language</option>
                      {
                        language.languages?.map((item) => {
                          return <option value={item} >{item}</option>
                        })
                      }
                    </select>
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    Religion
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td >
                    <select  className={`form-select sel-td  ${(errorDisp && formData.religion?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="religion" onChange={(e) => handleChangeFormData(e)}>
                      <option selected>Religion</option>
                      {
                        religion.religion?.map((item) => {
                          return <option value={item} >{item}</option>
                        })
                      }
                    </select>
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    Food
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td >
                    <select  className={`form-select sel-td  ${(errorDisp && formData.food?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="food" onChange={(e) => handleChangeFormData(e)}>
                      <option value="" selected>Food</option>
                      <option value="Veg" >Veg</option>
                      <option value="Non-Veg" >Non-Veg</option>
                    </select>
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    State
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td>
                    <select  className={`form-select sel-td  ${(errorDisp && formData.state?.length <= 1 )? 'is-invalid':''}`} aria-label="" 
                    name="state" onChange={(e) => handleChangeFormData(e)}>
                      <option value="" selected>State</option>
                      {
                        stateDist.states?.map((item) => {
                          return <option value={item.state} >{item.state}</option>
                        })
                      }
                    </select>
                  </td>
                </tr>
                <tr >
                  <td className="lightFont">
                    Country
                  </td>
                  <td style={{    justifyContent: "center"}}>
                    :
                  </td>
                  <td >
                    <select  className={`form-select sel-td  ${(errorDisp && formData.country?.length <= 1 )? 'is-invalid':''}`} aria-label="" name="country" onChange={(e) => handleChangeFormData(e)}>
                      <option value="" selected>Country</option>
                      <option value="India" >India</option>
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            
          </div>
          
          <p className={`text-center ${retMsg.type}`}>{retMsg.msg}</p>
          <div className="d-grid gap-2 col-3 mx-auto mt-4 ">
              <button type="submit" className="btn btn-primary bg-dark p-2"  disabled={loading} >SAVE</button>
          </div>
        </form>
    );
}